<template>
  <div>
    <div v-if="filteredImages != ''" class="cont">
      <div class="images-wrapper"
           v-for="(image, index) in filteredImages"
           :key="index">
        <div class="row">
          <div class="image-col col-12">
            <div class="image-container">
              <!-- Top Overlay -->
              <div class="d-flex justify-content-between top-overlay">
                <div>
                  <p class="user-name mb-0">
                    <img class="user-image"
                         :src="image.userImage"
                         alt="user-image"
                         v-if="image.userImage" />
                    <img class="user-image"
                         src="../../../assets/images/sample_student_profile.png"
                         alt="user-image"
                         v-else />
                    {{ image.studentName }}
                  </p>
                </div>
                <!-- Approve, Reject, & Report Icon -->
                <div class="image-actions text-center">
                  <i class="fas fa-check approve-icon"
                     @click="updateStatus(image, 'approved')"
                     title="Aprove Image">
                  </i>
                  <br>
                  <i class="fas fa-times reject-icon"
                     @click="updateStatus(image, 'rejected')"
                     title="Reject Image">
                  </i>
                  <br>
                  <i class="fas fa-flag report-icon"
                     @click="reportImage(image)"
                     title="Report Image">
                  </i>
                </div>
              </div>

              <!-- Image -->
              <img class="pending-image img-fluid"
                   :src="thumbnailDir + image.name"
                   alt="pending-image"
                   @click="showLightbox(image.name)" />
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <hr class="white">
          </div>
        </div>
      </div>

      <lightbox id="mylightbox"
                ref="lightbox"
                :directory="thumbnailDir"
                :filter="galleryFilter"
                :images="filteredImages"
                :timeoutDuration="5000">
      </lightbox>

      <!-- Load More -->
      <div class="row" v-if="imagesCurrentPage != imagesLastPage">
        <div class="col text-center">
          <b-button class="btn btn-h3-small btn-h3-blue mb-2 mt-2"
                    type="button"
                    @click="loadMoreImages">
            Load More
          </b-button>
        </div>
      </div>
    </div>

    <!-- No Pending Images -->
    <div class="row" v-else>
      <div class="col">
        <p class="mt-2 text-center">No pending images</p>
      </div>
    </div>
  </div>
</template>

<script>
  const ReportContent = () => import('@/components/modals/ReportContent.vue');

  export default {
    name : 'PendingImages',
    data() {
      return {
        images            : [],
        imagesPerPage     : 10,
        imagesCurrentPage : 0,
        imagesLastPage    : 0,
        thumbnailDir      : process.env.VUE_APP_CONTENT_BASE_URL,
        galleryFilter     : 'all',
        colWidth          : 0,
        imgHeight         : 0,
      }
    },
    mounted() {
      this.getPendingImages(0);
    },
    watch : {
      '$route' () {
        this.getPendingImages(0);
      },
    },
    computed : {
      filteredImages : function() {
        if (this.images) {
          const result = [];

          for (const element of this.images) {
            result.push({
              filter            : 'all',
              name              : element.path + 'thumbnails/med.jpg',
              id                : element.ucc_id,
              studentName       : element.nickname,
              approvalRequestId : element.approval_request_id,
              userImage         : element.profile_img_url,
              url               : element.url,
              userId            : element.user_id,
              guardianId        : element.guardian_id,
            });
          }

          return result;
        }

        return null;
      },
    },
    methods : {

      /**
       * To view image's whole photo
       * @param imageName Image name
       */
      showLightbox : function(imageName) {
        this.$refs.lightbox.show(imageName);
      },

      /**
       * Get all pending images per Classroom
       * @param currentPage Pagination's current page
       */
      getPendingImages(currentPage = this.imagesCurrentPage) {
        this.$http.get('api/approval_request/images', {
          params : {
            page        : currentPage + 1,
            perPage     : this.imagesPerPage,
            schoolId    : this.$store.getters['user/user'].school_id,
            classroomId : this.$route.params.classroomId,
          },
        }).then(response => {
          this.imagesCurrentPage = response.data.currentPage;
          this.imagesLastPage    = response.data.lastPage;

          if (this.imagesCurrentPage > 1) {
            const arr1 = this.images;
            const arr2 = response.data.data;

            this.images = arr1.concat(arr2);
          } else this.images = response.data.data;
        }).catch(() => {
          this.images = [];
        });
      },

      /**
       * Load more images
       */
      loadMoreImages() {
        this.getPendingImages();
      },

      /**
       * Approve or Reject Image request
       * @param image Image data
       * @param action Action to make (i.e. 'approved' or 'rejected')
       */
      updateStatus(image, action) {
        this.$http.put('api/approval_request', {
          "status"            : action,
          "userId"            : this.$store.getters['user/user'].user_id,
          "approvalRequestId" : image.approvalRequestId,
        }).then(() => {
          /**
           * TODO: Find other way to update the ff.
           */
          // 1. Pending Image List
          this.getPendingImages(0); // reset currentPage to 0

          // 2. Classroom Image List on Grand Parent via Parent component
          //    Classroom.vue -> Grand Parent
          //    PendingApproval.vue -> Parent Component
          this.$emit('update-parent-image');

          this.$notify({
            group : 'notif',
            type  : (action === 'approved') ? 'success' : 'warn',
            text  : (action === 'approved') ? 'Approved Image from ' +
              image.studentName : 'Rejected Image from ' + image.studentName,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Report Image
       * @param image
       */
      reportImage(image) {
        this.$modal.show(ReportContent, {
          mediaData : image,
          type      : 'image',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/classroom/pending-images";
</style>
